<script lang="ts" setup>
const { t } = useI18n();
</script>

<template>
  <ContactFormWrapper
    :show-concern-selection="false"
    sheet-background="#bbe9e4"
    outline-color="#84d9d0"
  >
    <template #description>
      <div class="mt-2 mb-5 mb-lg-6 text-center">
        {{ t("contactForm.saxonyAi.description") }}
      </div>
    </template>
  </ContactFormWrapper>
</template>

<style scoped lang="scss"></style>
